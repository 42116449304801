import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs';
import bookConfig from 'src/app/shared/constants/book-config';
import { EPages } from 'src/app/shared/enums/pages.enum';
import { PageControllerService } from 'src/app/shell/services/page-controller.service';

import {
  GetProfessorContentAction,
  GetSummaryAction,
  SetBookIdAction
} from './book.actions';
import {
  BookColorsModel,
  BookMascotModel,
  BookProfessorModel,
  BookSerieModel
} from './book.model';
import {
  PageCategoryModel,
  PageCreativeSpaceModel,
  PageModel
} from '../../models/book.model';
import { ConfigService } from '../../services/config.service';
import { BookService } from '../../services/requests/book.service';

export interface BookStateModel {
  portfolioId: number | null;
  bookId: number | null;
  colors: BookColorsModel | null;
  mascot: BookMascotModel | null;
  serie: BookSerieModel | null;
  school: string | null;
  professor: BookProfessorModel | null;
}

const BOOK_STATE_TOKEN = new StateToken<BookStateModel>('book');

@State<BookStateModel>({
  name: BOOK_STATE_TOKEN,
  defaults: {
    portfolioId: null,
    bookId: null,
    colors: null,
    mascot: null,
    serie: null,
    school: null,
    professor: null
  }
})
@Injectable()
export class BookState {
  constructor(
    private bookService: BookService,
    private pageControllerService: PageControllerService,
    private configService: ConfigService
  ) {}

  get conectaBookIds() {
    return this.configService.config?.conectaBookIds || [];
  }

  @Selector() static portfolioId({
    portfolioId
  }: BookStateModel): number | null {
    return portfolioId;
  }

  @Selector() static bookId({ bookId }: BookStateModel): number | null {
    return bookId;
  }

  @Selector() static externalStrapiId({
    serie
  }: BookStateModel): number | null {
    return serie?.externalStrapiId ?? null;
  }

  @Selector() static school({ school }: BookStateModel): string | null {
    return school;
  }

  @Selector() static serie({ serie }: BookStateModel): BookSerieModel | null {
    return serie;
  }

  @Selector() static colors({
    colors
  }: BookStateModel): BookColorsModel | null {
    return colors;
  }

  @Selector() static mascot({
    mascot
  }: BookStateModel): BookMascotModel | null {
    return mascot;
  }

  @Selector() static professor({
    professor
  }: BookStateModel): BookProfessorModel | null {
    return professor;
  }

  @Action(SetBookIdAction) setBookId(
    { patchState }: StateContext<BookStateModel>,
    { payload }: SetBookIdAction
  ): void {
    patchState({
      bookId: payload
    });
  }

  @Action(GetSummaryAction) getSummary(
    { getState }: StateContext<BookStateModel>,
    { payload }: GetSummaryAction
  ) {
    const { bookId } = payload;

    return this.bookService
      .getSummary({
        bookId: String(bookId)
      })
      .pipe(
        tap(({ pages }) => this._handleRenderPages(pages, getState(), bookId))
      );
  }

  @Action(GetProfessorContentAction) getProfessorContent(
    { patchState }: StateContext<BookStateModel>,
    { payload }: GetProfessorContentAction
  ) {
    return this.bookService.getProfessorContent(String(payload.bookId)).pipe(
      tap(({ attributes }) => {
        const { colors, mascot } =
          bookConfig[attributes?.serie.id_externo_strapi] || {};

        patchState({
          portfolioId: attributes?.portfolio?.data.id,
          colors,
          mascot,
          school: attributes?.escola,
          serie: {
            color: attributes?.serie.cor,
            externalSegmentId: attributes?.serie.id_externo_segmento,
            externalStrapiId: attributes?.serie.id_externo_strapi,
            id: attributes?.serie.id,
            name: attributes?.serie.nome,
            className: attributes?.turma,
            classDescription: attributes?.pagina_turma?.descricao,
            classPhoto:
              attributes?.pagina_turma?.midia?.data?.attributes?.formats?.small
                ?.url
          },
          professor: {
            name: attributes?.professor?.data?.attributes?.apelido,
            description: attributes?.professor?.data?.attributes?.descricao,
            photo:
              attributes?.professor?.data?.attributes?.foto?.data?.attributes
                ?.url
          }
        });
      })
    );
  }

  private _handleRenderPages(
    pages: PageModel,
    state: BookStateModel,
    bookId: number
  ) {
    const { serie, professor } = state;
    const { classPhoto, classDescription } = serie || {};
    const { photo: professorPhoto, description: professorBio } =
      professor || {};

    const shouldRenderAuthorsPage = !!(
      classPhoto ||
      classDescription ||
      professorPhoto ||
      professorBio
    );

    if (shouldRenderAuthorsPage) {
      this.pageControllerService.setPage(
        {
          type: EPages.authors
        },
        1
      );
    }

    this._buildDynamicPages(pages, bookId);
  }

  private _buildDynamicPages(pages: PageModel, bookId: number) {
    const initialPageIndex =
      this.pageControllerService.snapshot.dynamicPagesCount;
    let pageIndex = initialPageIndex + 1;

    pageIndex = this._buildCategoriesPages(pages.categories, pageIndex);

    if (pages.creativeSpaces.length > 0) {
      pageIndex = this._buildCreativeSpacePages(
        pages.creativeSpaces,
        pageIndex
      );
    }

    const finalPages = !this.conectaBookIds.includes(bookId)
      ? [
          EPages.all_lessons,
          EPages.resources_materials,
          EPages.like_next_year,
          EPages.cover_back
        ]
      : [EPages.like_next_year];

    for (const page of finalPages) {
      this.pageControllerService.setDynamicPage({
        type: page,
        index: pageIndex++
      });
    }

    this.pageControllerService.buildPages();
  }

  private _buildCategoriesPages(
    categories: PageCategoryModel[],
    pageIndex: number
  ) {
    for (const category of categories) {
      this.pageControllerService.setDynamicPage({
        type: EPages.category_lesson,
        index: pageIndex++,
        id: category.category.id
      });

      for (const lesson of category.lessons) {
        this.pageControllerService.setDynamicPage({
          type: EPages.lesson,
          index: pageIndex++,
          id: lesson.id
        });
      }
    }

    return pageIndex;
  }

  private _buildCreativeSpacePages(
    creativeSpaces: PageCreativeSpaceModel[],
    pageIndex: number
  ) {
    this.pageControllerService.setDynamicPage({
      type: EPages.intro_creative_space,
      index: pageIndex++
    });

    for (const space of creativeSpaces) {
      this.pageControllerService.setDynamicPage({
        type: EPages.creative_space,
        index: pageIndex++,
        id: space.id
      });
    }

    return pageIndex;
  }
}
